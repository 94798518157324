import React from 'react'
import classNames from 'classnames'
import CarouselNav from '@/atoms/CarouselNav'
import { useSupportsTouch } from '@/utils/ScreenUtils'
import useCarousel from '@/utils/useCarousel'

export function BasicCarousel({
  children,
  containerClassName,
  showGradients,
}: {
  children: React.ReactNode
  containerClassName?: string
  showGradients?: boolean
}) {
  const [ref, { scrollForward, canScrollForward, scrollBack, canScrollBack, orientation, pages }] =
    useCarousel<HTMLDivElement>()

  const onlyOnePage = pages === 1
  const showLeftNav = !onlyOnePage && canScrollBack
  const showRightNav = !onlyOnePage && canScrollForward
  const supportsTouch = useSupportsTouch()

  return (
    <div className="relative">
      <div className="flex items-center gap-x-2 overflow-hidden">
        <div
          ref={ref}
          className={classNames(
            'flex w-full snap-x snap-mandatory scroll-px-4 gap-4 overflow-x-scroll overscroll-x-contain px-4 scrollbar-none',
            containerClassName,
          )}
        >
          {children}
        </div>
      </div>

      {showLeftNav && showGradients && (
        <div className="pointer-events-none absolute left-0 top-0 z-10 hidden h-full w-16 bg-[linear-gradient(270deg,rgba(245,245,245,0)_0%,#F5F5F5_100%)] md:block" />
      )}

      {showRightNav && showGradients && (
        <div className="pointer-events-none absolute right-0 top-0 z-10 hidden h-full w-16 bg-[linear-gradient(270deg,#F5F5F5_0%,rgba(245,245,245,0)_100%)] md:block" />
      )}

      {showLeftNav && (
        <CarouselNav
          onClick={scrollBack}
          active={canScrollBack}
          orientation={orientation}
          direction="back"
          className={classNames(
            'top-0 z-10 p-2 opacity-80 hover:opacity-100',
            !supportsTouch ? 'flex' : 'hidden lg:flex',
          )}
          buttonClassName="border-none cursor-pointer h-10 w-10 flex items-center justify-center"
          iconSize={24}
        />
      )}

      {showRightNav && (
        <CarouselNav
          onClick={scrollForward}
          active={canScrollForward}
          orientation={orientation}
          direction="forward"
          className={classNames(
            'top-0 z-10 p-2 opacity-80 hover:opacity-100',
            !supportsTouch ? 'flex' : 'hidden lg:flex',
          )}
          buttonClassName="border-none cursor-pointer h-10 w-10 flex items-center justify-center"
          iconSize={24}
        />
      )}
    </div>
  )
}
