import { gql as shopifyGQL } from '@apollo/client'

export const getShopifyCustomerAndOrders = shopifyGQL/* GraphQL */ `
  query getShopifyCustomerAndOrders($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: 50) {
        edges {
          node {
            id
            cancelReason
            lineItems(first: 50) {
              edges {
                node {
                  quantity
                  variant {
                    id
                    product {
                      id
                      productType
                    }
                  }
                  title
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const getShopifyAccessTokenFromMultipass = shopifyGQL/* GraphQL */ `
  mutation getShopifyAccessTokenFromMultipass($multipassToken: String!) {
    customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
      customerAccessToken {
        accessToken
      }
    }
  }
`

export const productFragment = shopifyGQL/* GraphQL */ `
  fragment productFragment on Product {
    title
    id
    description
    tags
    featuredImage {
      url
    }
    handle
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    productType
    totalInventory
    variants(first: 100) {
      edges {
        node {
          components(first: 10) {
            edges {
              node {
                productVariant {
                  id
                  title
                  sku
                  product {
                    title
                    id
                    description
                    tags
                  }
                  price {
                    amount
                    currencyCode
                  }
                  metafield(namespace: "custom", key: "component_parents") {
                    value
                  }
                }
                quantity
              }
            }
          }
          id
          title
          product {
            title
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
    options {
      id
      name
      values
    }
  }
`

export const GetShopifyProduct = shopifyGQL/* GraphQL */ `
  ${productFragment}
  query getShopifyProductById($id: ID!) {
    product(id: $id) {
      ...productFragment
    }
  }
`

export const GetShopifyCollection = shopifyGQL/* GraphQL */ `
  ${productFragment}
  query getShopifyCollection($id: ID!) {
    collection(id: $id) {
      id
      handle
      title
      description
      updatedAt
      products(first: 150, sortKey: MANUAL, reverse: false) {
        edges {
          node {
            ...productFragment
          }
        }
      }
    }
  }
`
