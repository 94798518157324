import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import memoize from 'lodash/memoize'

const createAuthLink = ({ accessToken }: { accessToken: string }) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    }
  })

interface ShopifyClientConstructor {
  accessToken: string
  baseUrl: string
}

export const createShopifyClient = memoize(
  ({ baseUrl, accessToken }: ShopifyClientConstructor) => {
    const backend = createHttpLink({ uri: baseUrl })
    return new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: ApolloLink.from([createAuthLink({ accessToken }), backend]),
      cache: new InMemoryCache(),
    })
  },
  ({ baseUrl, accessToken }) => `${baseUrl}_${accessToken}`,
)
