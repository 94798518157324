import { useEffect, useState, useCallback, useMemo } from 'react'
import { debounce } from 'lodash'
import useMeasure from '../useMeasure'
import { Options, ScrollState } from './types'
import { getOrientation, measureScroller } from './utils'

const defaultOptions: Options = {
  arrowKeyNavigation: false,
}

export const defaultState = {
  children: [],
  inView: [],
  offViewStart: [],
  offViewEnd: [],
  canScrollBack: false,
  canScrollForward: false,
  pages: 1,
  currentPage: 1,
}

const defaultScrollBackOptions = {
  behavior: 'smooth',
  inline: 'end',
  block: 'nearest',
} as const

const defaultScrollForwardOptions = {
  behavior: 'smooth',
  inline: 'start',
  block: 'nearest',
} as const

const defaultScrollToIndexOptions = {
  behavior: 'smooth',
  inline: 'center',
  block: 'nearest',
} as const

function useCarousel<T extends HTMLElement>(options: Partial<Options> = {}) {
  const { arrowKeyNavigation } = {
    ...defaultOptions,
    ...options,
  }

  const [scroller, scrollerMeasure] = useMeasure<T>()
  const [scrollState, setScrollState] = useState<ScrollState>(defaultState)
  const orientation = getOrientation(scroller)

  const handleScroll = useCallback(() => {
    const { children, inView, offViewStart, offViewEnd } = measureScroller(scroller)

    // TODO: debounce might need some tuning to prevent temporary
    // wonky states like inView.length === 0
    const inViewLength = Math.max(inView.length, 1)
    const pages = Math.ceil(children.length / inViewLength)
    const currentPage = pages - Math.ceil(offViewEnd.length / inViewLength)

    setScrollState({
      children,
      inView,
      offViewStart,
      offViewEnd,
      canScrollBack: offViewStart.length > 0,
      canScrollForward: offViewEnd.length > 0,
      pages: Math.max(pages, 1),
      currentPage: Math.max(currentPage, 1),
    })
  }, [scroller])

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 100), [handleScroll])

  const scrollBack = useCallback(
    (options: Partial<ScrollIntoViewOptions> = defaultScrollBackOptions) => {
      scrollState.offViewStart.at(-1)?.scrollIntoView({ ...defaultScrollBackOptions, ...options })
    },
    [scrollState.offViewStart],
  )

  const scrollForward = useCallback(
    (options: Partial<ScrollIntoViewOptions> = defaultScrollForwardOptions) => {
      scrollState.offViewEnd[0]?.scrollIntoView({
        ...defaultScrollForwardOptions,
        ...options,
      })
    },
    [scrollState.offViewEnd],
  )

  const scrollToIndex = useCallback(
    (idx: number, options: Partial<ScrollIntoViewOptions> = defaultScrollToIndexOptions) => {
      scroller.current?.children[idx]?.scrollIntoView({
        ...defaultScrollToIndexOptions,
        ...options,
      })
    },
    [scroller],
  )

  useEffect(() => {
    const el = scroller.current
    debouncedHandleScroll()
    el?.addEventListener('scroll', debouncedHandleScroll)
    return () => el?.removeEventListener('scroll', debouncedHandleScroll)
  }, [debouncedHandleScroll, scroller])

  useEffect(() => {
    if (!arrowKeyNavigation) {
      return
    }

    const handleKeydown = (e: KeyboardEvent) => {
      const prevKey = orientation === 'horizontal' ? 'ArrowLeft' : 'ArrowUp'
      const nextKey = orientation === 'horizontal' ? 'ArrowRight' : 'ArrowDown'
      if (e.key === prevKey && scrollState.canScrollBack) scrollBack()
      if (e.key === nextKey && scrollState.canScrollForward) scrollForward()
    }

    document.addEventListener('keydown', handleKeydown)

    return () => document.removeEventListener('keydown', handleKeydown)
  }, [scrollState, scrollBack, scrollForward, arrowKeyNavigation, orientation])

  useEffect(handleScroll, [scrollerMeasure, handleScroll])

  useEffect(() => {
    if (!scroller.current || typeof window.MutationObserver === 'undefined') return

    const mutationObserver = new MutationObserver(handleScroll)
    mutationObserver.observe(scroller.current, {
      childList: true,
    })

    return () => mutationObserver?.disconnect()
  }, [handleScroll, scroller])

  return [scroller, { ...scrollState, orientation, scrollBack, scrollForward, scrollToIndex }] as const
}

export default useCarousel
