import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import { Button } from '../Button'
import { CaretRightIcon } from '../Icons/CaretRightIcon'

export interface Props {
  active: boolean
  orientation: 'horizontal' | 'vertical'
  direction: 'back' | 'forward'
  onClick: (options?: ScrollOptions) => void
  className?: string
  buttonClassName?: string
  style?: CSSProperties
  theme?: 'light' | 'dark'
  iconSize?: number
}

const CarouselNav: React.FC<Props> = ({
  active,
  orientation,
  direction,
  onClick,
  className,
  buttonClassName,
  style,
  theme = 'light',
  iconSize,
}) => {
  return (
    <div
      className={classNames(
        'absolute flex justify-center items-center pointer-events-none',
        'transition-opacity duration-300',
        {
          'top-0 bottom-0': orientation === 'horizontal',
          'left-0 right-0': orientation === 'vertical',
          'left-0': orientation === 'horizontal' && direction === 'back',
          'right-0': orientation === 'horizontal' && direction === 'forward',
          'top-0': orientation === 'vertical' && direction === 'back',
          'bottom-0': orientation === 'vertical' && direction === 'forward',
          'opacity-0': !active,
        },
        className,
      )}
      style={style}
    >
      <Button
        variant="plain"
        onClick={() => onClick()}
        className={classNames(
          'pointer-events-auto rounded-full border-2 p-1',
          {
            'border-white  bg-core-gray-950': theme === 'light',
            'border-none  bg-white': theme === 'dark',
          },
          buttonClassName,
        )}
        aria-label={`Scroll ${direction}`}
        disabled={!active}
      >
        <CaretRightIcon
          size={iconSize}
          color={theme === 'light' ? 'white' : 'core-gray-950'}
          className={classNames(
            { 'w-5 h-5': !iconSize },
            {
              'rotate-180': orientation === 'horizontal' && direction === 'back',
              'rotate-[270deg]': orientation === 'vertical' && direction === 'back',
              'rotate-90': orientation === 'vertical' && direction === 'forward',
            },
          )}
        />
      </Button>
    </div>
  )
}

export default CarouselNav
