import { useState, useEffect, RefObject, useSyncExternalStore, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

export enum ScreenType {
  mobile = 'mobile',
  mobileplus = 'mobileplus',
  tablet = 'tablet',
  desktop = 'desktop',
}

export enum MinScreenBreakpoints {
  mobile = 0,
  mobileplus = 640,
  tablet = 768,
  desktop = 1280,
}

export const useScreenType = () => {
  const [screenType, setScreenType] = useState<ScreenType | undefined>()

  const handleMobileScreenResize = (matches: boolean) => {
    if (matches) setScreenType(ScreenType.mobile)
  }
  const handleMobilePlusScreenResize = (matches: boolean) => {
    if (matches) setScreenType(ScreenType.mobileplus)
  }
  const handleTabletScreenResize = (matches: boolean) => {
    if (matches) setScreenType(ScreenType.tablet)
  }
  const handleDesktopScreenResize = (matches: boolean) => {
    if (matches) setScreenType(ScreenType.desktop)
  }

  const isMobile = useMediaQuery({ maxWidth: 640 }, undefined, handleMobileScreenResize)
  const isMobilePlus = useMediaQuery({ maxWidth: 767, minWidth: 641 }, undefined, handleMobilePlusScreenResize)
  const isTablet = useMediaQuery({ maxWidth: 1279, minWidth: 768 }, undefined, handleTabletScreenResize)
  const isDesktop = useMediaQuery({ minWidth: 1280 }, undefined, handleDesktopScreenResize)

  useEffect(() => {
    if (isMobile) setScreenType(ScreenType.mobile)
    if (isMobilePlus) setScreenType(ScreenType.mobileplus)
    if (isTablet) setScreenType(ScreenType.tablet)
    if (isDesktop) setScreenType(ScreenType.desktop)
  }, [isDesktop, isMobile, isTablet, isMobilePlus])

  return screenType
}

export const useOnScreen = (ref: RefObject<Element>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const localRef = ref.current

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
    if (!localRef || !observer) return

    const currentRef = ref.current

    if (!currentRef || !observer) return

    observer.observe(currentRef)

    // remove observer as soon as component is unmounted
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [ref, localRef])

  return {
    isIntersecting,
  }
}

export const subscribe = (callback: { (this: Window, ev: UIEvent): unknown; (this: Window, ev: UIEvent): unknown }) => {
  window.addEventListener('resize', callback)
  return () => {
    window.removeEventListener('resize', callback)
  }
}

export const useDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const dimensions = useSyncExternalStore(
    subscribe,
    () =>
      JSON.stringify({
        width: ref.current?.offsetWidth ?? 0,
        height: ref.current?.offsetHeight ?? 0,
      }),
    () =>
      JSON.stringify({
        width: ref.current?.offsetWidth ?? 0,
        height: ref.current?.offsetHeight ?? 0,
      }),
  )
  return useMemo(() => JSON.parse(dimensions), [dimensions])
}

export const useSupportsTouch = (): boolean => {
  const [supportsTouch, setSupportsTouch] = useState(false)

  useEffect(() => {
    const checkTouchSupport = () => {
      return typeof window !== 'undefined' && ('ontouchstart' in window || (navigator?.msMaxTouchPoints ?? 0) > 0)
    }

    setSupportsTouch(checkTouchSupport())
  }, [])

  return supportsTouch
}
